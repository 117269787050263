.slide {
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.heading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
}

.contents {
    display: grid;
    justify-content: space-evenly;
    align-content: start;
}

.entry {
    color: white;
    background-color: rgba(0,0,0,0.5);
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    color: white;
    text-align: center;
    background-color: rgba(0,0,0,0.5);
}
