.bgimage {
    background-image: url("images/smohero.jpg");

    width: 100vw;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;
}

.spacer {
    display: inline-block;
    padding-left: 1%;
}
